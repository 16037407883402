// src/templates/posts/Podcast.js

import React, { useEffect, useState } from 'react'
import { graphql } from 'gatsby'
import Seo from 'gatsby-plugin-wpgraphql-seo'
import Layout from '../../components/Layout.js'
import PageTitle from '../../components/PageTitle'
import ResourceMetadata from '../../components/ResourceMetadata'
import ResourceHero from '../../components/ResourceHero.js'
import ResourceCards from '../../components/ResourceCards.js'
import parse from 'html-react-parser'
import RichText from '../../layouts/RichText.js'
import ShowMoreButton from '../../components/Buttons/SeeMoreButton.js'

function Podcast({ data }) {
  const postData = data.wpPodcast
  const [showFullTranscript, setShowFullTranscript] = useState(false)

  useEffect(() => {
    if (typeof window !== 'undefined') {
      let postTags = []

      postData.podcast.relatedTopics.map((node, index) =>
        postTags.push(node.name)
      )

      const gtmArgs = {
        pageType: 'podcast',
        publishDate: postData.date,
        tags: postTags,
      }

      window.dataLayer = window.dataLayer || []
      window.dataLayer.push(gtmArgs)
    }
  }, [postData])

  const podcastTitle = postData.podcast?.podcastTitle
  const podcastTranscript = postData.podcast?.podcastTranscript || ''
  const paragraphs = podcastTranscript
    .split(/<\/?p>/)
    .filter(p => p.trim() !== '')
  const firstParagraph = paragraphs[0] ? `${paragraphs[0]}` : ''

  const toggleTranscript = () => setShowFullTranscript(!showFullTranscript)

  return (
    <Layout>
      <Seo post={postData} />

      <article className="space-y-responsive-xl-48">
        <section className="podcast-title text-center">
          <PageTitle
            label="Podcast"
            title={postData.title}
            url={postData.uri}
          />

          <div className="container">
            <ResourceHero heroImage={postData.podcast.heroImage} />
          </div>

          {(postData.podcast.author || postData.podcast.relatedTopics) && (
            <ResourceMetadata
              author={postData.podcast.author}
              relatedTopics={postData.podcast.relatedTopics}
              date={postData.date}
            />
          )}
        </section>

        <article className="space-y-responsive-xl-48">
          <div className="container">
            {postData.podcast.audioEmbed && (
              <>{parse(postData.podcast.audioEmbed)}</>
            )}
          </div>
          <RichText text={postData.podcast.mainContent} />
          
          {podcastTranscript && (
            <div className="space-y-responsive-xl-28">
              <div className="container px-responsive-xl-64">
                <h2>{podcastTitle}</h2>
              </div>
              <div>
                <RichText
                  text={showFullTranscript ? podcastTranscript : firstParagraph}
                />
                <div
                  style={{ marginTop: '16px' }}
                  className="container mx-auto px-responsive-xl-64 text-center"
                >
                  <ShowMoreButton
                    title={showFullTranscript ? 'Show Less' : 'Show More'}
                    onClick={toggleTranscript}
                  />
                </div>
              </div>
            </div>
          )}
        </article>

        <section className="related-resources">
          <div className="container">
            <h2 className="mb-responsive-xl-32 border-b border-granite-200 pb-responsive-xl-8 text-center text-sm font-medium uppercase tracking-wide">
              Related Resources
            </h2>
            <ResourceCards
              manualResources={postData.podcast.relatedResources}
              automatedResources={data.allWpPodcast}
            />
          </div>
        </section>
      </article>
    </Layout>
  )
}

export default Podcast

export const query = graphql`
  query ($id: String!) {
    wpPodcast(id: { eq: $id }) {
      seo {
        ...SeoFragment
      }
      title
      uri
      date(formatString: "M/D/YYYY")
      podcast {
        author {
          ... on WpBio {
            id
            uri
            bio {
              firstName
              lastName
            }
          }
        }
        mainContent
        audioEmbed
        podcastTitle
        podcastTranscript
        heroImage {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(
                height: 1380
                width: 2100
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
                transformOptions: { cropFocus: CENTER }
              )
            }
          }
        }
        relatedTopics {
          uri
          name
        }
        relatedResources {
          ... on WpArticle {
            ...ArticleResourceFragment
          }
          ... on WpPodcast {
            ...PodcastResourceFragment
          }
          ... on WpReport {
            ...ReportResourceFragment
          }
          ... on WpWebinar {
            ...WebinarResourceFragment
          }
        }
      }
    }
    allWpPodcast(
      filter: { id: { ne: $id } }
      limit: 3
      sort: { fields: date, order: DESC }
    ) {
      edges {
        node {
          ...PodcastResourceFragment
        }
      }
    }
  }
`
