import React from 'react'
import PropTypes from 'prop-types'

const ShowMoreButton = ({ onClick, title }) => {
  return (
    <button onClick={onClick} className="btn btn-secondary">
      <div className="flex items-center gap-responsive-xl-20">{title}</div>
    </button>
  )
}

export default ShowMoreButton

ShowMoreButton.propTypes = {
  onClick: PropTypes.func,
  title: PropTypes.string,
}
